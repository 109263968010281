import React, { useEffect, useRef, useState } from "react";
import L from "leaflet";
import { renderToString } from "react-dom/server";
import { Icon } from "@material-ui/core";
import PersonPinCircleIcon from "@mui/icons-material/PersonPinCircle";
import "../../styles/CustomMarker.scss";

const customIconClass1 = "leaflet-div-icon1";

const CustomMarker = new L.Icon({

  iconUrl: require("../../assets/images/star-marker.png"),
  iconRetinaUrl: require("../../assets/images/star-marker.png"),
  iconAnchor: [0, 0],
  popupAnchor: [0, -20],
  //   shadowUrl: 'path/to/shadow-image.png',
  shadowSize: [20, 20],
  shadowAnchor: [10, 10],
  iconSize: new L.Point(40, 40),
  className: customIconClass1,
});

export default CustomMarker;
