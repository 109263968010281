import React, { useEffect, useRef, useState } from "react";
import Sidebar from "../SideBar/SideBar";
import "../../styles/StaticPages/ContactUsPage.scss";
import "../../styles/StaticPages/ContactUsPage2.css";
import validator from "validator";
import Swal from "sweetalert2";
import axios from "axios";

const ContactUs = () => {
  const [userWantToGo, setUserWantToGo] = useState({
    lati: 18.964182,
    long: 77.281364,
  });
  const mapReff = useRef();
  const zoom = 4;

  const form = useRef();
  const [error, seterror] = useState(false);
  const [toSend, setToSend] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setToSend({
      ...toSend,
      [name]: value,
    });
  };

  // const preventEKeyPress = (e) => {
  //   if (e.key === 'e' || e.key === 'E') {
  //     e.preventDefault();
  //   }
  // };

  function preventEKeyPress(event) {
    const key = event.key.toLowerCase();
    if (key === "e" || key === "." || key === "-" || key === "+") {
      event.preventDefault();
    }
  }

  const sendEmail = async (e) => {
    debugger;
    e.preventDefault();

    if (
      toSend.name !== "" &&
      toSend.email !== "" &&
      toSend.phone !== "" &&
      toSend.message !== "" &&
      validator.isMobilePhone(toSend.phone) &&
      validator.isEmail(toSend.email)
    ) {
      // var showLoading = function () {
      //   Swal.fire({
      //     html: "Sending...",
      //     showConfirmButton: false,
      //     allowOutsideClick: false,
      //     willOpen: () => {
      //       Swal.showLoading();
      //     },

      //     willClose: () => {
      //       Swal.hideLoading();
      //     },
      //   });
      // };

      // showLoading();
      axios
        .post("http://localhost:8006/contactusform", toSend)
        // .post("https://api.saveallnations.com/contactusform", toSend)
        .then((res) => {
          debugger;

          console.log(res, "rrr111");
          Swal.fire({
            icon: "success",
            title: "Message Sent Successfully",
            text: "Thank you for providing your details, we will get in touch shortly!",
          });
          setToSend({
            name: "",
            email: "",
            phone: "",
            message: "",
          });
        })
        .catch((err) => {
          debugger;
          console.log(err, "aAAAAAAAAAAAAA");
          Swal.fire({
            icon: "error",
            title: "Message not sent",
            showLoaderOnConfirm: true,
            text: "Oops!, Something went wrong...,",
          });
        });
    } else {
      console.log("ttt111");
      seterror(true);
    }
  };

  useEffect(() => {
    if (userWantToGo.lati && userWantToGo.long) {
      mapReff.current?.flyTo([userWantToGo.lati, userWantToGo.long], zoom, {
        duration: 1,
        easeLinearity: 0.3,
      });

      setTimeout(() => {
        if (userWantToGo.lati !== 18.964182) {
          mapReff.current?.flyTo(
            [userWantToGo.lati, userWantToGo.long],
            zoom + 8,
            {
              duration: 1.5,
              easeLinearity: 0.3,
            }
          );
        } else {
          mapReff.current?.flyTo([userWantToGo.lati, userWantToGo.long], zoom, {
            duration: 1,
            easeLinearity: 0.3,
          });
        }
      }, 1000);
    }
  }, [userWantToGo]);

  const handleCoordinatesUpdate = (latitude, longitude) => {
    setUserWantToGo({ lati: latitude, long: longitude });
  };

  return (
    <div className="contactus-outer-wrapper">
      <div>
        <Sidebar handleCoordinatesUpdate={handleCoordinatesUpdate} />
      </div>
      <div className="container-fluid contactus-wrapper">
        <div className="row">
          <div className="contactus">
            <button className="contactus-btn">Contact Us</button>
          </div>

          <div className="container contactus-field-wrapper">
            <div class="contact">
              <div className="contact-form-box">
                <form id="contact-form" ref={form}>
                  <div className="field-wrap">
                    <label>
                      Name<span className="form-required">*</span>
                    </label>
                    <input
                      className="InputBox"
                      type="text"
                      name="name"
                      id="name"
                      placeholder="Enter Your Name"
                      value={toSend.name}
                      required
                      onChange={handleChange}
                    />
                    {error && toSend.name === "" && (
                      <div style={{ textAlign: "left", color: "red" }}>
                        Please Enter Correct Name!
                      </div>
                    )}
                  </div>

                  <div className="field-wrap">
                    <label>
                      E-mail<span className="form-required">*</span>
                    </label>
                    <input
                      className="InputBox"
                      type="email"
                      name="email"
                      id="email"
                      required
                      onChange={handleChange}
                      value={toSend.email}
                      placeholder="Eg. Username@gmail.com"
                    />

                    {error && !validator.isEmail(toSend.email) && (
                      <div style={{ textAlign: "left", color: "red" }}>
                        Please Enter a Valid Email Address!
                      </div>
                    )}
                  </div>

                  <div className="field-wrap">
                    <label>Mobile</label>
                    <input
                      className="InputBox"
                      type="number"
                      name="phone"
                      id="phone"
                      value={toSend.phone}
                      required
                      onKeyPress={preventEKeyPress}
                      onChange={(e) => {
                        if (e.target.value.length <= 10) {
                          handleChange(e);
                        }
                      }}
                      placeholder="Eg. +91-0000000000"
                    />

                    {error && !validator.isMobilePhone(toSend.phone) && (
                      <div style={{ textAlign: "left", color: "red" }}>
                        Please Enter Correct Mobile Number!
                      </div>
                    )}
                  </div>

                  <div className="field-wrap">
                    <label>
                      Your Message<span className="form-required">*</span>
                    </label>
                    <textarea
                      className="InputBox1"
                      id="Message"
                      name="message"
                      placeholder="Enter Your Messgae Here"
                      value={toSend.message}
                      required
                      onChange={handleChange}
                      rows="6"
                      cols="50"
                    ></textarea>

                    {error && toSend.message === "" && (
                      <div style={{ textAlign: "left", color: "red" }}>
                        Please Enter Correct Message!
                      </div>
                    )}
                  </div>
                </form>

                <div class="submit-btn-wrap">
                  <button className="submit-btn" onClick={sendEmail}>
                    Send Mail
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
