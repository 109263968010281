import React from 'react'
import Sidebar from "../SideBar/SideBar";

const Navigation = () => {
  return (
    <div>
      <div>
        <Sidebar />
        </div>
        navigation pages
    </div>
  )
}

export default Navigation;
