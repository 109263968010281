import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import Sidebar from "./components/SideBar/SideBar";

import LeafMap from "./components/LeafMap";
import DisplayMap from "./components/DisplayMap";
import Loader from "./components/AppComponents/Loader";
import { useState } from "react";
import MissionPage from "./components/Mission/MissionPage";
import Story from "./components/Story/Story";
import AboutUs from "./components/AboutUs/AboutUs";
import Upload from "./components/Upload/Upload";

import ContactUs from "./components/ContactUs/ContactUs";
import Navigation from "./components/Navigation/Navigation";
import KnowingGod from "./components/KnowingGod/KnowingGod";

import Home from "./components/Home/Home";

function App(props) {
  const [ShowLoader, setShowLoader] = useState(false);

  const [openPopupId, setOpenPopupId] = useState(null);

  const handleOpenPopup = (popupId) => {
    setOpenPopupId(popupId);
  };

  const handleClosePopup = () => {
    setOpenPopupId(false);
  };

  return (
    <div className="App">
      {ShowLoader ? <Loader></Loader> : ""}
      <Router>
        <Header />
        <Routes>
          <Route
            path="/"
            element={
              <LeafMap
                ShowLoader={ShowLoader}
                setShowLoader={setShowLoader}
              ></LeafMap>
            }
          />
          {/* <Route path="/mapimg" element={<DisplayMap />} /> */}
          <Route path="/mission" element={<MissionPage />} />
          <Route path="/story" element={<Story />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/upload" element={<Upload />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/navigation" element={<Navigation />} />
          <Route path="/knowingGod" element={<KnowingGod />} />
        </Routes>
        {/* <Home /> */}
      </Router>
    </div>
  );
}

export default App;
