import React, { useEffect, useRef, useState } from "react";
import "../../styles/StaticPages/KnowingGodPage.scss";
import { englishData } from "../LanguageTranslate/englishData";
import Sidebar from "../SideBar/SideBar";

import "../../styles/style.css";

const KnowingGod = () => {
  const [userWantToGo, setUserWantToGo] = useState({
    lati: 18.964182,
    long: 77.281364,
  });
  const mapReff = useRef();
  const zoom = 4;
  const minZoomLevell = 4;

  useEffect(() => {
    if (userWantToGo.lati && userWantToGo.long) {
      mapReff.current?.flyTo([userWantToGo.lati, userWantToGo.long], zoom, {
        duration: 1,
        easeLinearity: 0.3,
      });

      setTimeout(() => {
        if (userWantToGo.lati !== 18.964182) {
          mapReff.current?.flyTo(
            [userWantToGo.lati, userWantToGo.long],
            zoom + 8,
            {
              duration: 1.5,
              easeLinearity: 0.3,
            }
          );
        } else {
          mapReff.current?.flyTo([userWantToGo.lati, userWantToGo.long], zoom, {
            duration: 1,
            easeLinearity: 0.3,
          });
        }
      }, 1000);
    }
  }, [userWantToGo]);

  const handleCoordinatesUpdate = (latitude, longitude) => {
    setUserWantToGo({ lati: latitude, long: longitude });
  };

  return (
    <div className="knowinggod-outer-wrapper">
      <div>
        <Sidebar handleCoordinatesUpdate={handleCoordinatesUpdate} />
      </div>

      <div className="container-fluid knowinggod-wrapper">
        <div className="row">
          <div className="knowinggod">
            <button className="knowinggod-btn">KNOWING GOD</button>
          </div>

          {/* <div className='content-wrapper'>
              <div className='col-md-12 box-one-knowinggod'>
              {englishData.map((item) => (
              <div key={item.id}>
                {item.Knowinggod &&
                  item.Knowinggod.map((KnowinggodItem, index) => (
                    <div key={index}>
                      <div className='know-god-head'>
                      <h3>{KnowinggodItem.heading}</h3>
                    </div>

                    <div className='know-god-para-wrap'>
                      <p>{KnowinggodItem.para1}</p>
                      <p>{KnowinggodItem.para2}</p>
                      <p>{KnowinggodItem.para3}</p>
                      <p>{KnowinggodItem.para4}</p>
                      <b>{KnowinggodItem.biblesays}</b>
                      <b>{KnowinggodItem.bibletext}</b>
                      <p>{KnowinggodItem.para5}</p>
                      </div>
                    </div>
                  ))}
              </div>
            ))}
              </div>
            </div> */}

          <section className="section-knowing-god">
            <div className="container">
              <h1 className="heading-knowing-god center-text">
                Knowing God - The Author of Salvation
              </h1>

              <section>
                <h2 className="heading-secondary">Introduction:</h2>
                <p className="para margin-bottom-small">
                  God is Holy, and man in his best form with all his goodness,
                  cannot come into the presence of a Holy God. Man has to be
                  free from the bondage and slavery of sin.If we continue in sin
                  then we also have to receive punishment for our sins. God is
                  just and being just He has to punish us for all the wrong that
                  we do.
                </p>
              </section>

              <section>
                <h2 className="heading-secondary">Consequence of Sin:</h2>
                <p className="para margin-bottom-small">
                  If we continue in sin, then according to{" "}
                  <span className="highlight">
                    Rom 6:23, the wages of sin is death, but the free gift of
                    God is eternal life through Christ Jesus our Lord.
                  </span>
                </p>
                <p className="para margin-bottom-small">
                  So we are due to receive punishment for the many sins we have
                  committed. On the other hand, God is Love and He does not want
                  anyone to be destroyed or separated from Him. The love of God
                  beckons us, but His Holiness prevents us from coming to Him
                  and we are due punishment as He is just. Then how could sinful
                  man gain access or entrance into the presence of a God who is
                  Holy and fair? Let me give an illustration.
                </p>
              </section>

              <section>
                <h2 className="heading-secondary">An Illustration:</h2>
                <p className="para margin-bottom-small">
                  A man was caught by the police for robbery and was produced
                  before the court. The Judge happened to be his friend. But
                  being a just person, he passed the judgment. The man had to
                  pay a fine of Rs.500/- or be in jail for 7 days. After
                  pronouncing the judgment, the Judge himself came down and paid
                  the amount of Rs. 500/- on behalf of his friend, advising him
                  not to rob again. Now the judge had released his friend from
                  the punishment due. In this manner, the judge was able to meet
                  both ends. He was the judge as well as the deliverer.
                  (Illustration from Sam Doherty's Sunday School teaching)
                </p>
                <p className="para margin-bottom-small">
                  This is what God did. God is Love, Just, and Holy. He could
                  not deny Himself. But He also made a way. God destroyed sin’s
                  control over our life. So what is the way by which we sinful
                  people can reach God who is just and holy? God sent His only
                  son, Jesus Christ, to take the punishment for our sins, God
                  through His son Jesus Christ, opened a new way for us to come
                  to Him. God put upon Jesus the entire punishment of the sins
                  of the whole world, the sicknesses, and all the unlawful deeds
                  committed by everyone.
                </p>
                <p className="para ">
                  The Bible says in <span className="highlight">Isa 53:5:</span>
                </p>
                <blockquote className="para highlight margin-bottom-small">
                  But he was wounded and crushed for our sins. He was beaten
                  that we might have peace. He was whipped, and we were healed!
                </blockquote>
                <p className="para margin-bottom-small">
                  We should have been punished, but Jesus Christ died in our
                  place and took our sins and sicknesses on His body.So through
                  Jesus, we can come into the presence of God the Father. Jesus
                  is the way to God the Father.
                </p>
              </section>

              <section>
                {/* <h2 className="heading-secondary">The Solution: Jesus Christ</h2> */}
              </section>

              <section>
                <h2 className="heading-secondary">
                  Receiving Jesus into Your Life:
                </h2>
                <p className="para margin-bottom-small">
                  Do you have this question ‘What must I do’? You must believe
                  and receive Jesus into your lives, and when you do this, a
                  miracle happens. God makes you a new creature and frees you
                  from the gripping power of sin and the Evil One. You can make
                  this life and destiny-changing decision right now and on your
                  desktop. Say this simple prayer with all your heart to receive
                  Jesus into your life.
                </p>

                <p className="para margin-bottom-small highlight">
                  Lord Jesus, I am a sinner. I believe that you died for me on
                  the cross and shed your precious blood for me. Today and now
                  please wash me with your precious blood from every sin. I
                  believe that you rose again on the third day and now you are
                  seated at the right-hand side of God the Father. I invite you
                  to come into my heart and make me your child. Thank you Lord
                  for washing me from my sins with your blood, thank you Lord
                  for coming into my heart and making me your child. In Jesus
                  name I pray. Amen
                </p>
                <p className="para margin-bottom-small">
                  As you make Jesus the Lord of your life, God will be with you
                  and guide you. Read the Bible daily using the YouVersion app
                  or get a physical copy of the Bible
                </p>
                <p className="para margin-bottom-small">
                  You can Begin reading from the book of John. Get in touch by
                  sending an email to:{" "}
                  <a href="mailto:saveallnations@gmail.com">
                    saveallnations@gmail.com
                  </a>{" "}
                  and we will be praying for you.
                </p>
              </section>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default KnowingGod;
