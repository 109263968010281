import React, { useEffect, useState } from "react";
import "../../styles/SideBar/SearchTab.scss";
import { url1 } from "../../Content/Data";
import axios from "../../utils/axiosWrapper";
import SearchIcon from "@mui/icons-material/Search";

function SearchTab(props) {
  const { handleCoordinatesUpdate } = props;
  const [showResults, setShowResults] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [chruchs, setChruchs] = useState([]);

  // Update showResults state when searchValue changes
  useEffect(() => {
    setShowResults(searchValue.length > 0);
  }, [searchValue]);

  const handleSearchChange = (e) => {
    const newValue = e.target.value;
    setSearchValue(newValue);
    axios({
      method: "post",
      url: url1 + "search",
      data: {
        key: newValue,
      },
    })
      .then((response) => {
        console.log(response.data, "sea");
        setChruchs(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Handle clicking on a church
  const handleChurchClick = (event, latitude, longitude) => {
    event.stopPropagation();
    setShowResults(false);
    handleCoordinatesUpdate(latitude, longitude);
  };

  return (
    <div className="mainSearchBoxDiv">
      <div className="searchButton">
        <label htmlFor="searchButtonMain">
          <SearchIcon />
        </label>
        <input
          id="searchButtonMain"
          className="searchButtonMain"
          placeholder="Search Church/Leader/Language"
          onChange={handleSearchChange}
          maxLength={31}
        ></input>
      </div>
      {showResults && (
        <div className="results">
          <ul className="relative">
            {chruchs.length > 0 ? (
              chruchs.map((item) => (
                <li
                  className="one"
                  key={item.id}
                  onClick={(event) =>
                    handleChurchClick(event, item.latitude, item.longitude)
                  }
                >
                  <div className="churchLabel">
                    <span className="churchName">
                      <b> Church:</b> {item.churchName}
                    </span>
                    <br />
                    <span className="leaderName">
                      <b> Leader:</b> {item.leaderName}
                    </span>
                    <br />
                    <span className="location">
                      <b>Language:</b>
                      {item.location}
                    </span>
                  </div>
                </li>
              ))
            ) : (
              <li className="no-results">No results</li>
            )}
          </ul>
        </div>
      )}
    </div>
  );
}

export default SearchTab;
