export const englishData = [
  {
    id: 1,
    Home: [
      {
        head: "Story behind the song: 'How Great Thou Art'",
        para1: "aa",
        para2: "aa",
      },
    ],
  },
  {
    id: 2,
    Mission: [
      {
        conten1:
          "Mission - To Praise the Living God through the Classic OH LORD MY GOD ( Then sings my soul ) in all the languages of the world. Based upon the scripture from Psalms 67:2,3",
        conten2:
          "“That thy way may be known upon earth, Thy saving health among all nations. Let the people praise thee, O God; Let all the people praise thee.” Psalm 67:2-3 KJV",
        conten3:
          "That all the people of the world may know Thy Way and experience thy saving health during this time of the Global pandemic and unrest.",
      },
    ],
  },
  {
    id: 3,
    Aboutus: [
      {
        heading: "Eaglets Roar youth group India/ TEAM SaveAllNations",
      },
    ],
  },
  {
    id: 4,
    Knowinggod: [
      {
        heading: "THE AUTHOR OF SALVATION",

        para1:
          "God is Holy, and man in his best form with all his goodness, cannot come into the presence of a Holy God. Man has to be free from the bondage and slavery of sin. If we continue in sin then we also have to receive punishment for our sins. God is just and being just He has to punish us for all the wrong that we do. If we continue in sin then according to <b>Rom 6:23</b>… the wages of sin is death, but the free gift of God is eternal life through Christ Jesus our Lord.",
        para2:
          "So we are due to receive punishment for the many sins we have committed. On the other hand, God is Love and He does not want anyone to be destroyed or separated from Him. The love of God beckons us, but His Holiness prevents us from coming to Him and we are due punishment as He is just. Then how could sinful man gain access or entrance into the  presence of a God who is Holy and fair? Let me give an illustration.",
        para3:
          "A Story: A man was caught by the police for robbery and was produced before the court. The Judge happened to be his friend. But being a just person he passed the judgment. The man had to pay a fine of Rs. 500/- or be in jail for 7 days. After pronouncing the judgment the Judge himself came down and paid the amount of Rs. 500/- on behalf of his friend advising him not to rob again. Now the judge had released his friend from the punishment due. In this manner, the judge was able to meet both ends. He was the judge as well as the deliverer.   (Illustration from Sam Dohertys Sunday School teaching)",
        para4:
          "This is what God did. God is Love, Just, and Holy. He could not deny Himself. But He also made a way. God destroyed sin’s control over our life. So what is the way by which we sinful people can reach God who is just and holy? God sent His only son, Jesus Christ to take the punishment for our sins, God through His son Jesus Christ, opened a new way for us to come to Him. God put upon Jesus the entire punishment of the sins of the whole world, the sicknesses, and all the unlawful deeds committed by everyone.",
        biblesays: "The Bible says in Isa 53:5",
        bibletext: "But he was wounded and crushed for our sins. He was beaten that we might have peace. He was whipped, and we were healed!",
        para5: "We should have been punished, but Jesus Christ died in our place and took our sins and sicknesses on His body. So through Jesus, we can come into the presence of God the Father. Jesus is the way to God the Father. Do you have this question ‘What must I do ‘? You must believe and receive Jesus into your lives and when you do this a miracle happens. God makes you a new creature and frees you from the gripping power of sin and the Evil One. You can make this life and destiny-changing decision right now and on your desktop. Say this simple prayer with all your heart to receive Jesus into your life"
      },
    ],
  },
];
