import React from 'react';
import "../../styles/Popups/UploadINtroPopup.css";

const UploadIntroPopup = ({ handleClosePopup }) => {
  return (
    <div className="popup-container">
      <div className="popup">
        <button className="close-button" onClick={handleClosePopup}>
        <i class="fa fa-times"></i>
        </button>
        <h5 className='upload-popup-head'>UPLOAD INSTRUCTIONS for Audio/Video Creation</h5>

        <p>Language : English or the Local language (preferred ) of the State/Nation. </p>

        <p>Total Video = Song 3mins + Message 2 mins + Prayer Declaration 1 min = Total 6 mins</p>
      </div>
    </div>
  );
};

export default UploadIntroPopup;
