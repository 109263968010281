import React, { useEffect, useState } from "react";
import "../../styles/SideBar/SideBar1.css";
import { NavLink } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import CrisisAlertIcon from "@mui/icons-material/CrisisAlert";
import InfoIcon from "@mui/icons-material/Info";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import CallIcon from "@mui/icons-material/Call";
import NavigationIcon from "@mui/icons-material/Navigation";
import ChurchIcon from "@mui/icons-material/Church";
import Translation from "../LanguageTranslate/Translation";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import TextField from "@mui/material/TextField";
import SearchTab from "./searchTab";

const SideBar = (props) => {
  const [isOpen, setIsopen] = useState(false);

  const ToggleSidebar = () => {
    isOpen === true ? setIsopen(false) : setIsopen(true);
  };

  // const [sideBarLatLong, setSideBarLatLong] = useState({ sideBarLatitude: null, sideBarLongitude: null });

  // const updateLatLong = (latitude, longitude) => {
  //   setSideBarLatLong({ sideBarLatitude: latitude, sideBarLongitude: latitude });
  // };

  // useEffect(() => {
  //   console.log(sideBarLatLong.sideBarLatitude);
  //   console.log(sideBarLatLong.sideBarLongitude);
  // }, [sideBarLatLong]);

  const [coordinates, setCoordinates] = useState({
    latitude: null,
    longitude: null,
  });

  const handleCoordinatesUpdate = (latitude, longitude) => {
    // Update the latitude and longitude in the state
    setCoordinates({ latitude, longitude });
  };

  useEffect(() => {
    if (coordinates.latitude !== null && coordinates.longitude !== null) {
      console.log(
        "Coordinates updated:",
        coordinates.latitude,
        coordinates.longitude
      );
    }
    props.handleCoordinatesUpdate(coordinates.latitude, coordinates.longitude);
  }, [coordinates]);

  return (
    <>
      <div className="sidebar_top_wrapper">
        <div className="container-fluid">
          <nav className="navbar navbar-expand-lg sidebar_top">
            <div className="container-fluid containerfluid">
              {/* <a className="navbar-brand text-primary mr-0">Company Logo</a> */}
              <div className="form-inline ml-auto">
                <div
                  className="btn btn-primary sidebarDiv"
                  onClick={ToggleSidebar}
                >
                  <i className="fa fa-bars"></i>
                </div>
              </div>

              {/* <div className="caption_wrapper">
                <h6 className="hhh">ONE SONG OF PRAISE - TO ONE GOD</h6>
              </div> */}
              {/* <SearchTab x={sideBarLatLong.sideBarLatitude} y={sideBarLatLong.sideBarLongitude} setX={setSideBarLatLong.sideBarLatitude} setY={setSideBarLatLong.sideBarLongitude} /> */}

              <SearchTab handleCoordinatesUpdate={handleCoordinatesUpdate} />

              {/* <SearchTab /> */}
            </div>
          </nav>

          <div className={`sidebar ${isOpen == true ? "active" : ""}`}>
            <div className="sd-header">
              {/* <h4 className="mb-0">Client Side</h4> */}
              <div className="btn btn-primary" onClick={ToggleSidebar}>
                <i className="fa fa-times"></i>
              </div>
            </div>
            <div className="sd-body">
              <ul>
                <li>
                  <a className="sd-link" href="/">
                    <HomeIcon />
                    Home
                  </a>
                </li>
                <li>
                  <a className="sd-link" href="/story">
                    <AutoStoriesIcon />
                    Story
                  </a>
                </li>
                <li>
                  <a className="sd-link" href="/mission">
                    <CrisisAlertIcon />
                    Mission
                  </a>
                </li>
                <li>
                  <a className="sd-link" href="/upload">
                    <DriveFolderUploadIcon />
                    Upload
                  </a>
                </li>
                <li>
                  <a className="sd-link" href="/aboutus">
                    <InfoIcon />
                    About Us{" "}
                  </a>
                </li>
                <li>
                  <a className="sd-link" href="/contactus">
                    <CallIcon />
                    Contact Us{" "}
                  </a>
                </li>
                <li>
                  <a
                    className="sd-link"
                    // href="/navigation"
                  >
                    {" "}
                    <NavigationIcon />
                    Navigation{" "}
                  </a>
                </li>
                <li>
                  <a className="sd-link" href="/knowingGod">
                    {" "}
                    <ChurchIcon />
                    Knowing God{" "}
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div
            className={`sidebar-overlay ${isOpen == true ? "active" : ""}`}
            onClick={ToggleSidebar}
          ></div>
        </div>
      </div>
    </>
  );
};

export default SideBar;
