import React, { useEffect, useRef, useState } from "react";
import "../../styles/StaticPages/MissionPage.scss";
import { englishData } from "../LanguageTranslate/englishData";
import Sidebar from "../SideBar/SideBar";
// import "../../styles/style.css";

const MissionPage = (props) => {
  const [userWantToGo, setUserWantToGo] = useState({
    lati: 18.964182,
    long: 77.281364,
  });
  const mapReff = useRef();
  const zoom = 4;
  const minZoomLevell = 4;

  useEffect(() => {
    if (userWantToGo.lati && userWantToGo.long) {
      mapReff.current?.flyTo([userWantToGo.lati, userWantToGo.long], zoom, {
        duration: 1,
        easeLinearity: 0.3,
      });

      setTimeout(() => {
        if (userWantToGo.lati !== 18.964182) {
          mapReff.current?.flyTo(
            [userWantToGo.lati, userWantToGo.long],
            zoom + 8,
            {
              duration: 1.5,
              easeLinearity: 0.3,
            }
          );
        } else {
          mapReff.current?.flyTo([userWantToGo.lati, userWantToGo.long], zoom, {
            duration: 1,
            easeLinearity: 0.3,
          });
        }
      }, 1000);
    }
  }, [userWantToGo]);

  const handleCoordinatesUpdate = (latitude, longitude) => {
    setUserWantToGo({ lati: latitude, long: longitude });
  };

  return (
    <div className="mission-outer-wrapper">
      <div>
        <Sidebar handleCoordinatesUpdate={handleCoordinatesUpdate} />
      </div>
      <div className="container-fluid mission-wrapper">
        <div className="row">
          <div className="mission">
            <button className="mission-btn">MISSION</button>
          </div>

          <div className="content-wrapper">
            <div className="container">
              <section className="mission-inner">
                <div class="header-container">
                  <h2 className="mission-heading">Our Mission</h2>
                  <p className="para-one">
                    To Praise the Living God through the Classic OH LORD MY GOD
                    ( Then sings my soul ) in all the languages of the world.
                    Based upon the scripture from Psalms 67:2,3
                  </p>
                  <br />

                  <p className="para-one">
                    “That <strong>thy way </strong>may be known upon earth, Thy{" "}
                    <strong>saving health</strong> among all nations. ” Psalm
                    67:2-3 KJV
                  </p>

                  <p className="para-one">
                    Let the people praise thee, O God;{" "}
                    <strong style={{ textDecoration: "underline" }}>
                      Let all the people praise thee.”
                    </strong>
                  </p>

                  <p className="para-one">Psalm 67:2-3 KJV</p>
                  <br />

                  <p className="para-one">
                    That all the people of the world may know{" "}
                    <strong>Thy Way</strong> and experience thy
                    <strong> saving health</strong> during this time of the
                    Global pandemic and unrest.
                  </p>
                </div>
              </section>

              <section className="section-psalm-67">
                <div className="container">
                  <h2 className="heading-secondary">Psalm 67 :</h2>
                  <div className="verses">
                    <div class="verse">
                      <p className="para-two">
                        <strong>1</strong> God be merciful to us and bless us,
                        And cause His face to shine upon us, Selah
                      </p>
                    </div>

                    <div class="verse">
                      <p className="para-two">
                        <strong>2</strong> That Your way may be known on earth,
                        Your salvation among all nations.
                      </p>
                    </div>

                    <div class="verse">
                      <p className="para-two">
                        <strong>3</strong> The peoples praise You, O God; Let
                        all the peoples praise You.
                      </p>
                    </div>

                    <div class="verse">
                      <p className="para-two">
                        <strong>4</strong> Oh, let the nations be glad and sing
                        for joy! For You shall judge the people righteously, And
                        govern the nations on earth. Selah
                      </p>
                    </div>

                    <div class="verse">
                      <p className="para-two">
                        <strong>5</strong> The peoples praise You, O God; Let
                        all the peoples praise You.
                      </p>
                    </div>

                    <div class="verse">
                      <p className="para-two">
                        <strong>6</strong> Then the earth shall yield her
                        increase; God, our own God, shall bless us.
                      </p>
                    </div>

                    <div class="verse">
                      <p className="para-two">
                        <strong>7</strong> God shall bless us, And all the ends
                        of the earth shall fear Him.
                      </p>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MissionPage;
