import React, { useState } from "react";
import "../styles/Header.scss";
import saveAll from "../assets/images/saveAll.png";
import LangTranslate from "./LangTranslate";
import pslamsGif from "../assets/images/pslamsGif.gif";

import pslams from "../assets/images/pslams.png";
// import saveBanner from "../assets/images/Banner.JPG";
import saveBanner from "../assets/images/Home-Banner-Indonesian.jpg";

import Counter from "./Counter";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import axios from "../utils/axiosWrapper";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Mapwrapper from "../components/SearchField/MapWrapper";
import Translation from "../components/LanguageTranslate/Translation";

import YourComponent from "../components/LanguageTranslate/YourComponent";

const Header = () => {

  //   var myVideos = [];

  //   window.URL = window.URL || window.webkitURL;
  //   document.getElementById("fileUp").onchange = setFileInfo;

  //   function setFileInfo() {
  //     var files = this.files;
  //     myVideos.push(files[0]);
  //     var video = document.createElement("video");
  //     video.preload = "metadata";

  //     video.onloadedmetadata = function () {
  //       window.URL.revokeObjectURL(video.src);
  //       var duration = video.duration;
  //       myVideos[myVideos.length - 1].duration = duration;
  //       updateInfos();
  //     };

  //     video.src = URL.createObjectURL(files[0]);
  //   }

  //   function updateInfos() {
  //     var infos = document.getElementById("infos");
  //     infos.textContent = "";
  //     for (var i = 0; i < myVideos.length; i++) {
  //       infos.textContent +=
  //         myVideos[i].name + " duration: " + myVideos[i].duration + "\n";
  //     }
  //   }

  return (
    <div>
      <div className="outbor">
        {/* nav className="navbar navbar-expand-sm"  */}
        <nav >
          <div className="container-fluid navbarHeadC">
            <img className="saveAllLogo" src={saveAll} alt="saveallimg" />
            <div className="bannerDiv">
              {/* <div className="banner"></div> */}
              <img className="banner" src={saveBanner} alt="Banner" />
            </div>
            <img className="pslamGif" src={pslamsGif} alt="pslam" width="25%" />


            {/* <div className="comingSoon"> */}
            {/* <div className=""> */}
            {/* <h1 className="comesoon">COMING SOON</h1> */}
            {/* <img className="saveAllBanner" src={saveBanner} alt="saveallbannerimg" /> */}
            {/* </div> */}

            {/* <div className="LangTranslate-comp">
              <LangTranslate />
              <YourComponent />
            </div> */}

            

            {/* <div>
                <Mapwrapper />
              </div> */}

            {/* <div>
                <Counter />
              </div> */}

            {/* <div className="pslamLogo">
              <img className="pslamGif" src={pslamsGif} alt="pslam" width="25%" />
            </div> */}

            {/* <div>
              <Translation />
            </div> */}
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Header;
