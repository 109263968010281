import React, { useEffect, useRef, useState } from "react";
import "../../styles/StaticPages/AboutUsPage.scss";
import { englishData } from "../LanguageTranslate/englishData";
import Sidebar from "../SideBar/SideBar";

import "../../styles/style.css";

const AboutUs = () => {
  const [userWantToGo, setUserWantToGo] = useState({
    lati: 18.964182,
    long: 77.281364,
  });
  const mapReff = useRef();
  const zoom = 4;
  const minZoomLevell = 4;

  useEffect(() => {
    if (userWantToGo.lati && userWantToGo.long) {
      mapReff.current?.flyTo([userWantToGo.lati, userWantToGo.long], zoom, {
        duration: 1,
        easeLinearity: 0.3,
      });

      setTimeout(() => {
        if (userWantToGo.lati !== 18.964182) {
          mapReff.current?.flyTo(
            [userWantToGo.lati, userWantToGo.long],
            zoom + 8,
            {
              duration: 1.5,
              easeLinearity: 0.3,
            }
          );
        } else {
          mapReff.current?.flyTo([userWantToGo.lati, userWantToGo.long], zoom, {
            duration: 1,
            easeLinearity: 0.3,
          });
        }
      }, 1000);
    }
  }, [userWantToGo]);

  const handleCoordinatesUpdate = (latitude, longitude) => {
    setUserWantToGo({ lati: latitude, long: longitude });
  };

  return (
    <div className="aboutus-outer-wrapper">
      <div>
        <Sidebar handleCoordinatesUpdate={handleCoordinatesUpdate} />
      </div>

      <div className="container-fluid aboutus-wrapper">
        <div className="row">
          <div className="aboutus">
            <button className="aboutus-btn">ABOUT US</button>
          </div>

          <div className="content-wrapper">
            <div className="col-md-12 box-one-about">
              {englishData.map((item) => (
                <div key={item.id}>
                  {item.Aboutus &&
                    item.Aboutus.map((AboutusItem, index) => (
                      <div key={index}>
                        <p>{AboutusItem.heading}</p>
                      </div>
                    ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
