import React, { useEffect, useRef, useState } from "react";
import { englishData } from "../LanguageTranslate/englishData";
import Sidebar from "../SideBar/SideBar";

import "../../styles/StaticPages/UploadPage.scss";
import "../../styles/style.css";

import uploadform from "../../assets/images/Uploadform/upload-form.png";
import shadows from "@mui/material/styles/shadows";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import LanguageIcon from "@mui/icons-material/Language";
import MusicNoteIcon from "@mui/icons-material/MusicNote";

import LibraryMusicIcon from "@mui/icons-material/LibraryMusic";
import MessageIcon from "@mui/icons-material/Message";
import ChurchIcon from "@mui/icons-material/Church";

const Upload = () => {
  const [userWantToGo, setUserWantToGo] = useState({
    lati: 18.964182,
    long: 77.281364,
  });

  const mapReff = useRef();
  const zoom = 4;
  const minZoomLevell = 4;

  useEffect(() => {
    if (userWantToGo.lati && userWantToGo.long) {
      mapReff.current?.flyTo([userWantToGo.lati, userWantToGo.long], zoom, {
        duration: 1,
        easeLinearity: 0.3,
      });

      setTimeout(() => {
        if (userWantToGo.lati !== 18.964182) {
          mapReff.current?.flyTo(
            [userWantToGo.lati, userWantToGo.long],
            zoom + 8,
            {
              duration: 1.5,
              easeLinearity: 0.3,
            }
          );
        } else {
          mapReff.current?.flyTo([userWantToGo.lati, userWantToGo.long], zoom, {
            duration: 1,
            easeLinearity: 0.3,
          });
        }
      }, 1000);
    }
  }, [userWantToGo]);

  const handleCoordinatesUpdate = (latitude, longitude) => {
    setUserWantToGo({ lati: latitude, long: longitude });
  };

  return (
    <div className="upload-outer-wrapper">
      <div>
        <Sidebar handleCoordinatesUpdate={handleCoordinatesUpdate} />
      </div>

      <div className="container-fluid upload-wrapper">
        <div className="row">
          <button className="upload-btn">UPLOAD</button>

          <div className="upload">
            <section className="upload-section-one">
              <div className="upload-header">
                1. Instructions to prepare the Audio/Video File :
              </div>

              <p className="inner-content">
                As we build a website to host the Classic “Oh Lord my God” (
                Then sings my soul ) in all the languages of the world. We
                lovingly and prayerfully invite you, in the name of the Lord
                JESUS, to participate and take time to UPLOAD your best offering
                of Praise, Word and Prayer Declaration, to bring hope, comfort
                and healing to the people of the world, during this time of
                Global pandemic, uncertainty and unrest.
              </p>

              <div className="inst-lang-total-wrap">
                <p className="instruction">
                  Instructions for Audio/Video Creation
                </p>

                <p className="language">
                  <b>Language :</b> English or the Local language (preferred )
                  of the State/Nation.
                </p>

                <p className="total-video">
                  <b>Total Video</b> = Song 3mins + Message 2 mins + Prayer
                  Declaration 1 min = Total 6 mins
                </p>
              </div>

              <div class="row">
                <div class="col-lg-4 col-md-6 mb-4 card-column">
                  <div className="instruction-song-wrap">
                    <div className="card">
                      <div className="card-header">
                        <h5 className="instruction-song">
                          <span>
                            <LibraryMusicIcon />
                            &nbsp;&nbsp;
                          </span>
                          Instructions for Song
                        </h5>
                        <span className="duration"> ( Duration - 3 mins )</span>
                      </div>
                      <div className="card-body">
                        <p>
                          The Audio/Video song can be sung by an individual,
                          Church choir, youth group, Sunday school or all
                          inclusive in different segments.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-lg-4 col-md-6 mb-4 card-column">
                  <div className="instruction-song-wrap">
                    <div className="card">
                      <div className="card-header">
                        <h5 className="instruction-song">
                          <span>
                            <MessageIcon />
                            &nbsp;&nbsp;
                          </span>
                          Instructions for Message{" "}
                        </h5>
                        <span className="duration"> ( Duration - 2 mins )</span>
                      </div>
                      <div className="card-body">
                        <p>
                          <b>Message</b> - A brief message of salvation, healing
                          and second coming of the Lord Jesus Christ.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-lg-4 col-md-6 mb-4 card-column">
                  <div className="instruction-song-wrap">
                    <div className="card">
                      <div className="card-header">
                        <h5 className="instruction-song">
                          <span>
                            <ChurchIcon />
                            &nbsp;&nbsp;
                          </span>
                          Instructions for Prayer Declaration
                        </h5>
                        <span className="duration">
                          {" "}
                          ( Duration - 2 mins )
                        </span>{" "}
                        &nbsp;
                        <span className="according">According to Ps 67:2,3 </span>
                      </div>
                      <div className="card-body">
                        <p>
                          “That thy way may be known upon earth, Thy saving
                          health among all nations. Let the people praise thee,
                          O God; Let all the people praise thee.” Psalm 67:2-3
                          KJV{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="leader-suggested-wrap">
                <ul>
                  <li>
                    For the salvation of the region from where the person is
                    watching.
                  </li>
                  <li>
                    Declare - Thy Kingdom come Thy will be done in the region
                    where the person is watching from, and in all the continents
                    of the world.{" "}
                  </li>
                  <li>
                    Pray a blessing for the viewer, his family and his country.{" "}
                  </li>
                </ul>
              </div>
            </section>

            <section className="upload-section-two">
              <div className="form-header">
                2. Details required for upload (The Contact Form) :{" "}
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-field-list">
                    <ul>
                      <li>1. Name of Church/Ministry *</li>
                      <li>2. Name of Leader *</li>
                      <li>3. Best Email id *</li>
                      <li>4. Upload Video ( Max 6 minutes )</li>
                      <li>5. Upload image (Church/Leader)</li>
                      <li>6. Location</li>
                      <li>7. Language *</li>
                      <li>8. Country *</li>
                      <li>9. Mobile Nos</li>
                      <li>10. Any other information</li>
                    </ul>
                  </div>

                  <div className="video-image-wrap">
                    <h5 className="upload-format">
                      Upload File Required Format :
                    </h5>
                    <ul>
                      <li>
                        For Video, 1080 resolution - 1920 x 1080 pixels (full
                        HD), MP4.
                      </li>

                      <li>
                        For Image, (.PNG/JPEG) extension format. upto 24Kb.
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="upload-form-img-wrap">
                    {/* <h5>Upload Form : </h5> */}
                    <img
                      src={uploadform}
                      className="form-img"
                      alt="Upload Form"
                    ></img>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Upload;
