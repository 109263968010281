import React, { useEffect, useRef, useState } from "react";
import "../styles/LeafMap.scss";
import "../styles/UserVideoPopup.scss";
import "leaflet/dist/leaflet.css";
import { MapContainer } from "react-leaflet/MapContainer";
import { useMap } from "react-leaflet";
import song1 from "../assets/music/song.mp3";
import video1 from "../assets/videos/video1.mp4";
import video2 from "../assets/videos/PD_SAN_Ver1.0.mp4";
import video3 from "../assets/videos/JM_SAN_Ver1.1.mp4";
import playIcon from "../assets/icons/play-circle.png";
import pauseIcon from "../assets/icons/pause.png";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import axios from "../utils/axiosWrapper";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import L from "leaflet";
import { Map, TileLayer, Marker, useMapEvents, Popup } from "react-leaflet";
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import "leaflet-geosearch/dist/geosearch.css";
import { GeoSearchControl, OpenStreetMapProvider } from "leaflet-geosearch";
import icon1 from "./SearchField/constants";
import CustomMarker from "./CustomMarker/CustomMarker.js";
import CustomMarker2 from "./CustomMarker/customMarker2.js";
import CustomMarker3 from "./CustomMarker/CustomMarker3.js";
import CustomMarker4 from "./CustomMarker/CustomMarker4.js";
import StarsIcon from "@mui/icons-material/Stars";
import { url1 } from "../Content/Data";
// import Button from 'react-bootstrap/Button';
import Modal from "react-bootstrap/Modal";
import Sidebar from "../components/SideBar/SideBar";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import UploadIntroPopup from "./Popups/UploadInstoPopuop";
import $ from "jquery";
import Swal from "sweetalert2";
import "../styles/OtpVerfiy/otpVerify.css";
import child_batch from "../../src/assets/images/child_batch.png";
import { BorderLeft } from "@mui/icons-material";
import Select from "react-select";
// ------------Carousel----------
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Carousel from "react-bootstrap/Carousel";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";

// search function
function LeafletgeoSearch() {
  const map = useMap();
  useEffect(() => {
    const provider = new OpenStreetMapProvider();

    const searchControl = new GeoSearchControl({
      provider,
      marker: {
        icon1,
      },
    });

    map.addControl(searchControl);

    return () => map.removeControl(searchControl);
  }, []);

  return null;
}

let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
});

L.Marker.prototype.options.icon = DefaultIcon;

// actual start of component /*****/
const LeafMap = (props) => {
  const [ShowLoader, setShowLoader] = useState(false);
  const [Carousel, setCarousel] = useState(false);
  const [openPopupId, setOpenPopupId] = useState(null);

  const handleOpenPopup = (popupId) => {
    setOpenPopupId(popupId);
  };

  const handleClosePopup = () => {
    setOpenPopupId(false);
  };

  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    location: "",
  };

  const [data, setData] = useState(initialValues);

  // validation for email form
  const [error, setError] = useState(false);
  const [song, setSong] = useState("");
  const [btn, setBtn] = useState(true);
  const [audioBtn, setAudioBtn] = useState(true);
  const player = useRef(null);
  const videoPlayer1 = useRef(null);
  const videoPlayer2 = useRef();
  const videoPlayer3 = useRef();
  const videoPlayer4 = useRef();
  // const [coordinatesLeaf, setCoordinatesLeaf] = useState({
  //   latitude: 18.964182,
  //   longitude: 77.281364,
  // });
  // const minZoomLeve = 4;
  // const mapRef = useRef(null);

  const emailRegEx =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  // useEffect(() => {
  //   // console.log(song);
  //   setSong(song1);
  //   // console.log("after", song);
  //   pauseAudio();
  //   setTimeout(() => {
  //     // console.log(player.current.audio.current.pause());
  //   }, 300);
  // }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
    // console.log(data);
    if (data["email"] !== "") {
      setBtn(false);
    }
  };

  const handleCancel = (e) => {
    document.getElementById("userForm").style.display = "none";
  };

  // email collect form
  const handleSubmit = (e) => {
    if (data.email === "") {
      toast.warning("Please enter the valid email id");
      setError(true);
    } else {
      if (emailRegEx.test(data.email)) {
        axios
          .post("/createUser", data)
          .then((res) => {
            // console.log(res.data);
            var data = res.data;
            // console.log(data.status["code"]);
            if (data.status["code"] === 200) {
              // toast.success(
              //   `Thanks for your details \n Please check your mail`
              // );
              Swal.fire({
                // icon: "success",
                // title: "Message Sent Successfully",
                text: "Thanks for your details \n Please check your mail.",
              });
              document.getElementById("userForm").style.display = "none";
            } else {
              toast.error(data.status["message"]);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        // toast.warning("Please enter the valid email id");
        Swal.fire({
          icon: "info",
          // title: "Message not sent",
          showLoaderOnConfirm: true,
          text: "Please enter the valid email id",
        });
        setError(true);
      }
    }
  };

  // const playAudio = () => {
  //   player.current.audio.current.play();
  //   document.getElementById("playIconBtn").style.display = "none";
  //   document.getElementById("pauseIconBtn").style.display = "block";
  // };

  // const pauseAudio = () => {
  //   player.current.audio.current.pause();
  //   document.getElementById("playIconBtn").style.display = "block";
  //   document.getElementById("pauseIconBtn").style.display = "none";
  // };

  // getting user location
  const [coordinates, setCoordinates] = useState(null);

  // Map Onclick
  const handleMapClick = (e) => {
    const { lat, lng } = e.latlng;
    setCoordinates({ lat, lng });

    console.log(`cooo: Latitude: ${lat}, Longitude: ${lng}`);
  };

  const handlePopupClose = () => {
    // console.log("jjhjhjhjhjh");
    // setShowPopup(false);
    document.getElementById("leaflet-zoom-animated").style.display = "none";

    // console.log(
    //   (document.getElementById("leaflet-zoom-animated").style.display = "none"),
    //   "PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP"
    // );
  };

  const MapEvents = () => {
    useMapEvents({
      click: handleMapClick,
    });
    return null;
  };

  const [formData1, setFormData1] = useState({
    churchName: "",
    leaderName: "",
    email: "",
    videoFile: "",
    imageFile: "",
    location: "",
    country: "",
    mobile: "",
    suggestions: "",
    // latitude: "",
    // longitude: "",
  });

  // UPLOAD FORM VALIDATION START***************************************************
  const [churchName, setChurchName] = useState("");
  const [leaderName, setLeaderName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [videoFile, setVideoFile] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [location, setLocation] = useState("");
  const [language, setLanguage] = useState("");
  const [errors, setErrors] = useState({});
  // const [field1Valid, setField1Valid] = useState(false);

  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  // UPLOAD FORM VALIDATION END*****************************************************

  const [imagePreSignedUrl, setImagePreSignedUrl] = useState("");
  const [videoPreSignedUrl, setVideoPreSignedUrl] = useState("");

  const fetchPreSignedUrl = async () => {
    setImagePreSignedUrl(data.url);
    setVideoPreSignedUrl(data.url);
  };

  useEffect(() => {
    fetchPreSignedUrl();
  }, []);

  // SMH file size.
  const handleFileChange = (e, event) => {
    // SIZE restrict start
    if (e.target.name === "imageFile" || e.target.name === "videoFile") {
      const file = e.target.files[0];
      const fileSize = file.size; // in bytes
      const maxSizeInBytes = 60 * 1024 * 1024; // 60MB
      const maxSizeInMB = 60;

      if (fileSize > maxSizeInBytes) {
        // File size exceeds the maximum allowed size
        const fileSizeInMB = fileSize / (1024 * 1024);
        const fileSizeMessage = `maximum limit of ${maxSizeInMB}MB.`;
        document.getElementById("fileSizeMessage").textContent =
          fileSizeMessage;
        // Reset the file input
        e.target.value = null;
        return; // Exit the function
      } else {
        document.getElementById("fileSizeMessage").textContent = "";
      }

      setFormData1((prev) => {
        return { ...prev, [e.target.name]: file };
      });

      var userupload = {
        filename: "test/" + String(file.name).replace(/ +/g, ""),
        file_type: file.type,
      };
      // SIZE restrict end

      axios({
        method: "post",
        url: url1 + "awspresignedurl",
        data: userupload,
      })
        .then((response) => {
          if (e.target.name === "imageFile") {
            // console.log(response.data.data.url, "imageimageimage");
            setImagePreSignedUrl(response.data.data.url);
          } else if (e.target.name === "videoFile") {
            // console.log(response.data.data.url, "videoFilevideoFile");
            setVideoPreSignedUrl(response.data.data.url);
          }
        })
        .catch((error) => {});
    } else {
      setFormData1((prev) => {
        return { ...prev, [e.target.name]: e.target.value };
      });
    }
  };

  const handleFileChange2 = (e) => {
    // console.log(e.target.name, "1234567890");
    if (e.target.name === "imageFile" || e.target.name === "videoFile") {
      // console.log(e.target.files[0], "&&&&&&&&&&&&&&&&&&&&&&&&");
      setFormData1((prev) => {
        return { ...prev, [e.target.name]: e.target.files[0] };
      });

      var userupload = {
        filename: "test/" + String(e.target.files[0].name).replace(/ +/g, ""),
        file_type: e.target.files[0].type,
      };

      axios({
        method: "post",
        url: url1 + "awspresignedurl",
        data: userupload,
      })
        .then((response) => {
          // console.log(response.data.data.url, "videovideovideo");
          setVideoPreSignedUrl(response.data.data.url);
        })
        .catch((error) => {});
    } else {
      setFormData1((prev) => {
        return { ...prev, [e.target.name]: e.target.value };
      });
    }
  };

  const confirmpopup = (e) => {
    setShowModal(true);
  };

  // SMH handleRequestOtp
  const [mailSentSuccess, setMailSentSuccess] = useState(0);
  const [mailSentFail, setMailSentFail] = useState(0);
  const [mailDisable, setMailDisable] = useState(false);

  const handleRequestOtp = (e) => {
    e.preventDefault();
    if (formData1.email !== "") {
      // console.log(formData1);
      setMailDisable(true); // Disable the "Request OTP" button
      const otpMail = formData1.email;
      // console.log(otpMail);
      axios({
        method: "post",
        url: url1 + "mailotp",
        data: {
          email: formData1.email,
        },
      })
        .then((response) => {
          setMailSentFail(0);
          setMailSentSuccess(1);
          // console.log("You are ok.");
        })
        .catch((error) => {
          setMailSentFail(1);
          setMailDisable(false); // Enable the "Request OTP" button on failure
          // console.log(error);
        });
    } else {
      setMailSentFail(1);
    }
  };

  // SMH Verifying OTP.
  const [hideFormElements, setHideFormElements] = useState(1);
  const [otpVerifySuccess, setOtpVerifySuccess] = useState(0);
  const [otpVerifyFail, setOtpVerifyFail] = useState(0);

  // const [otpValue, setOtpValue] = useState({
  //   one: "",
  //   two: "",
  //   three: "",
  //   four: "",
  // });
  // const handleOtpValue = (event) => {
  //   const { id, value } = event.target;
  //   setOtpValue((previousOtp) => ({ ...previousOtp, [id]: value }));
  // };

  const [otpValue, setOtpValue] = useState("");
  const [enableVerify, setEnableVerify] = useState(true);
  const handleOtpValue = (event) => {
    const { id, value } = event.target;
    setOtpValue(value);
    // if (value.length === 4 && otpVerifySuccess == true) {
    //   setEnableVerify(0);
    // } else {
    //   setEnableVerify(1);
    // }
    setEnableVerify(false);
  };
  const handleVerifyOtp = (e) => {
    e.preventDefault();
    const otpMail = formData1.email;
    // const otpData =
    //   otpValue.one + otpValue.two + otpValue.three + otpValue.four;
    const otpData = otpValue;
    if (otpData.length !== 0) {
      const formDataaa = new FormData();
      // Append your data to the FormData instance
      formDataaa.append("email", otpMail);
      formDataaa.append("otp", otpData);
      // console.log("mail:", otpMail);
      // console.log("OTP:", otpData);
      axios({
        method: "post",
        url: url1 + "verifyotp",
        data: {
          email: otpMail,
          otp: otpData,
        },
      })
        .then((response) => {
          // setOtpVerifyFail
          setHideFormElements(0);
          setOtpVerifyFail(0);
          setOtpVerifySuccess(1);
          setMailSentSuccess(0); // Reset mailSentSuccess state after successful OTP verification
          setMailSentFail(0); // Hide the mail sent fail message on successful OTP verification

          // setMailDisable(0);
          // console.log("Otp send successfully");
        })
        .catch((error) => {
          setOtpVerifyFail(1);
          setEnableVerify(true); // Enable the "Verify" button on OTP verification failure
          setMailSentSuccess(0);
          setOtpVerifySuccess(0); // Reset otpVerifySuccess state on OTP verification failure

          // console.log("Error occurred in handleVerifyOTP: ", error);
        });
    } else {
      setOtpVerifyFail(1);
    }
  };

  // SMH Handle form submit.
  const handleSubmitForm = (e) => {
    e.preventDefault();
    setErrors({});
    if (
      churchName.trim() !== "" &&
      leaderName.trim() !== "" &&
      email.trim() !== "" &&
      videoFile &&
      imageFile &&
      location.trim() !== "" &&
      language.trim() !== ""
    ) {
      const formData = new FormData();
      formData.append("churchName", formData1.churchName);
      formData.append("leaderName", formData1.leaderName);
      formData.append("email", formData1.email);
      formData.append("imageFile", imagePreSignedUrl.split("?")[0]);
      formData.append("videoFile", videoPreSignedUrl.split("?")[0]);
      formData.append("location", formData1.location);
      formData.append("country", formData1.country);
      formData.append("mobile", formData1.mobile);
      formData.append("suggestions", formData1.suggestions);
      formData.append("latitude", coordinates.lat);
      formData.append("longitude", coordinates.lng);
      props.setShowLoader(true);
      axios({
        method: "put",
        url: imagePreSignedUrl,
        headers: {
          "Content-Type": "image/png",
          "X-Amz-ACL": "public-read",
        },
        data: formData1.imageFile,
      })
        .then((response) => {
          props.setShowLoader(false);
          var popup_div = document.querySelector(".leaflet-popup");
          popup_div.remove();
          setShowModal(false);
          axios({
            method: "put",
            url: videoPreSignedUrl,
            headers: {
              "Content-Type": "video/mp4",
              "X-Amz-ACL": "public-read",
            },
            data: formData1.videoFile,
          })
            .then((response) => {
              axios({
                method: "post",
                url: url1 + "uploadUserData",
                data: formData,
              })
                .then((response) => {
                  props.setShowLoader(false);
                  setFormData1({
                    churchName: "",
                    leaderName: "",
                    email: "",
                    videoFile: "",
                    imageFile: "",
                    location: "",
                    country: "",
                    mobile: "",
                    suggestions: "",
                  });
                  Swal.fire({
                    icon: "success",
                    title: "Thank you!!!",
                    text: "You have successfully uploaded the file and details.",
                  });
                  setTimeout(() => {
                    window.location.reload();
                  }, 2000);
                  setHideFormElements(1);
                  setOtpVerifySuccess(0);
                })
                .catch((error) => {
                  // console.log(error);
                });
            })
            .catch((error) => {
              // console.log(error);
            });
        })
        .catch((error) => {
          // console.log("*&*&*&*&*&*&");
        });
    } else {
      const validationErrors = {};
      if (churchName.trim() === "") {
        validationErrors.churchName = "Church Name is required";
      }
      if (leaderName.trim() === "") {
        validationErrors.leaderName = "Leader Name is required";
      }
      if (email.trim() === "") {
        validationErrors.email = "Email is required";
      } else if (!isValidEmail(email)) {
        validationErrors.email = "Invalid email format";
      }
      // if (mobile.trim() === "") {
      //   validationErrors.mobile = "Mobile is required";
      // }
      if (!videoFile) {
        validationErrors.videoFile = "Video file is required";
      }
      if (!imageFile) {
        validationErrors.imageFile = "Image file is required";
      }
      if (location.trim() === "") {
        validationErrors.location = "Language is required";
      }
      if (language.trim() === "") {
        validationErrors.language = "Country is required";
      }
      // If there are validation errors, set the state and stop further processing
      if (Object.keys(validationErrors).length > 0) {
        setErrors(validationErrors);
        setShowModal(false);
        return;
      }
    }
  };

  const handleFileChange3 = (e) => {
    const file = e.target.files[0];
    setVideoFile(file);
  };

  const isValidEmail = (email) => {
    // Basic email validation
    const emailRegex = /^\S+@\S+\.\S+$/;
    return emailRegex.test(email);
  };

  const isValidPhoneNumber = (mobile) => {
    const mobileNumberRegex = /^\d{10}$/;
    return mobileNumberRegex.test(mobile);
  };

  // show upload data in map GET api integration   NORMAL VIDEO
  const [markersData, setMarkersData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(40);
  const [loadingData1, setLoadingData1] = useState(true);

  // SEARTCH STRING FILTER
  const [searchFilter, setSearchFilter] = useState("");
  const encodedSearchFilter = encodeURIComponent(searchFilter);
  useEffect(() => {
    async function Tabledata1() {
      await axios({
        method: "get",
        url:
          url1 +
          `getverifyuserdata?page=${currentPage}&limit=${perPage}&verify=1&search=${encodedSearchFilter}`,
        // params: {
        //   limit: 10,
        //   offset: 0,
        // },
        // headers: {s
        //   "x-access-token": localStorage.getItem("token"),
        // },
      })
        .then((response) => {
          // console.log("$how$how upload data table", response.data.data.data);
          setMarkersData(response.data.data.data);
          // setUserDataTable(response.data.data.data);

          const filteredData = response.data.data.data;
          setMarkersData(filteredData);

          setLoadingData1(false);
          // console.log(searchFilter, "3333333333333333333");
        })
        .catch((error) => {
          // console.log("Error in Tabledata1", error);
        });
    }
    if (loadingData1) {
      Tabledata1();
    }
  }, [loadingData1, currentPage, perPage, searchFilter]);

  const handleSearch = () => {
    // console.log("OOOOOOOOOOO");
    setLoadingData1(true);
    // console.log("11111111111", loadingData1);
    setCurrentPage(1);
    // console.log("22222222222", currentPage);
  };

  // show upload data in map GET api integration FEATURED VIDEO
  // const [userDataTable, setUserDataTable] = useState([]);
  const [markersFeatureData, setMarkersFeatureData] = useState([]);
  const [childMarkersFeatureData, setChildMarkersFeatureData] = useState([]);
  const [currentPage2, setCurrentPage2] = useState(1);
  const [perPage2, setPerPage2] = useState(40);

  // users datas table
  useEffect(() => {
    async function Tabledata1() {
      await axios({
        method: "get",
        url:
          url1 +
          `getfeatureduserdata?page=${currentPage}&limit=${perPage}&verify=1&feature=1`,
      })
        .then((response) => {
          setMarkersFeatureData(response.data.data.data);
          setLoadingData1(false);
        })
        .catch((error) => {
          // console.log(error);
        });
    }
    if (loadingData1) {
      Tabledata1();
    }
  }, [loadingData1, currentPage, perPage]);

  // SMH child marker
  useEffect(() => {
    async function Tabledata1() {
      await axios({
        method: "get",
        url: url1 + `getchilduserdata?page=1&limit=20&child=1`,
      })
        .then((response) => {
          setChildMarkersFeatureData(response.data.data.data);
          setLoadingData1(false);
        })
        .catch((error) => {
          // console.log("Error occurred in SMH child marker.", error);
        });
    }
    if (loadingData1) {
      Tabledata1();
    }
  }, [loadingData1, currentPage, perPage]);

  const minZoomLevel = 2; // Set your desired minimum zoom level

  const tileLayerOptions = {
    bounds: [
      [-90, -180],
      [90, 180],
    ],
    tileSize: 512,
    zoomOffset: -1,
    attribution: "Map data © OpenStreetMap contributors",
  };

  // MARKER HOVER POPUP
  const markerRef = useRef(null);

  const handleMarkerMouseOver = () => {
    // console.log("hhohohohohohohohhohohoho");
    const marker = markerRef.current;
    if (marker) {
      marker.openPopup();
    }
  };

  const handleMarkerMouseOut = () => {
    // console.log("hhohohohohohohohhohohoho22222222");
    const marker = markerRef.current;
    if (marker) {
      marker.closePopup();
    }
  };

  // FEATURED VIDEO CAROUSEL
  const [userDataTabled, setUserDataTabled] = useState([]);

  useEffect(() => {
    async function Tabledata1() {
      await axios({
        method: "get",
        url:
          url1 +
          `getfeatureduserdata?page=${currentPage}&limit=${perPage}&feature=1`,
      })
        .then((response) => {
          // console.log(
          //   "$how$how upload data table fefefefefeffefef",
          //   response.data.data.data
          // );
          setUserDataTabled(response.data.data.data);

          // setMarkersData(response.data.data.data);
          // setLoadingData1(false);
        })
        .catch((error) => {
          // console.log(error, ">>><<><><>< data table");
        });
    }
    if (loadingData1) {
      Tabledata1();
    }
  }, [loadingData1, currentPage, perPage]);

  // SMH Code for teleporting when searched for a chruch or leader.
  // const [userWantToGo, setUserWantToGo] = useState({
  //   lati: 18.964182,
  //   long: 77.281364,
  // });

  // console.log(userWantToGo, "deflat");

  // const mapReff = useRef();
  // const zoom = 10;
  // // const minZoomLevell = 4;

  // useEffect(() => {
  //   if (userWantToGo.lati && userWantToGo.long) {
  //     mapReff.current?.flyTo([userWantToGo.lati, userWantToGo.long], zoom, {
  //       duration: 1,
  //       easeLinearity: 0.3,
  //     });

  //     setTimeout(() => {
  //       if (userWantToGo.lati !== 18.964182) {
  //         mapReff.current?.flyTo(
  //           [userWantToGo.lati, userWantToGo.long],
  //           zoom + 8,
  //           {
  //             duration: 1.5,
  //             easeLinearity: 0.3,
  //           }
  //         );
  //       } else {
  //         mapReff.current?.flyTo([userWantToGo.lati, userWantToGo.long], zoom, {
  //           duration: 1,
  //           easeLinearity: 0.3,
  //         });
  //       }
  //     }, 1000);
  //   }
  // }, [userWantToGo]);

  // const handleCoordinatesUpdate = (latitude, longitude) => {
  //   setUserWantToGo({ lati: latitude, long: longitude });
  // };

  const [defaultMapView, setDefaultMapView] = useState({
    lati: 18.964182,
    long: 77.281364,
    zoom: 4, // Set your default zoom level here
  });

  const [userWantToGo, setUserWantToGo] = useState({
    lati: defaultMapView.lati,
    long: defaultMapView.long,
    zoom: defaultMapView.zoom, // Set the same default zoom level initially
  });

  const mapReff = useRef();

  useEffect(() => {
    if (userWantToGo.lati && userWantToGo.long) {
      mapReff.current?.flyTo(
        [userWantToGo.lati, userWantToGo.long],
        userWantToGo.zoom,
        {
          duration: 1,
          easeLinearity: 0.3,
        }
      );

      setTimeout(() => {
        if (
          userWantToGo.lati !== defaultMapView.lati ||
          userWantToGo.long !== defaultMapView.long
        ) {
          mapReff.current?.flyTo(
            [userWantToGo.lati, userWantToGo.long],
            userWantToGo.zoom + 8,
            {
              duration: 1.5,
              easeLinearity: 0.3,
            }
          );
        } else {
          mapReff.current?.flyTo(
            [userWantToGo.lati, userWantToGo.long],
            userWantToGo.zoom,
            {
              duration: 1,
              easeLinearity: 0.3,
            }
          );
        }
      }, 1000);
    }
  }, [userWantToGo, defaultMapView]);

  // When updating the default view separately
  const handleDefaultViewUpdate = (latitude, longitude, zoom) => {
    setDefaultMapView({ lati: latitude, long: longitude, zoom: zoom });
  };

  // When updating for search results
  const handleCoordinatesUpdate = (latitude, longitude, zoom = 10) => {
    setUserWantToGo({ lati: latitude, long: longitude, zoom: zoom });
  };

  // --------------Carousel---------------
  // const [currentPage ,setcCurrentpage] = useState(1);
  // const [perPage , setPerPage] =useState(10);
  const [latestVideo, setlatestVideo] = useState([]);
  const [childVideo, setChildVideo] = useState([]);

  // function getVideoIdFromURL(url) {
  //   // console.log(url);
  //   // Extract the video ID from the URL using a regular expression
  //   const match = url.match(/v=([a-zA-Z0-9_-]+)/);
  //   if (match && match[1]) {
  //     return match[1];
  //   } else {
  //     // Handle the case where the URL is invalid or doesn't contain a video ID
  //     return ""; // You can return a default value or handle the error as needed
  //   }
  // }

  // ----------------------FEATURED VIDEO-------------
  useEffect(() => {
    try {
      axios({
        method: "get",
        url:
          url1 +
          `getfeatureduserdata?page=${currentPage}&limit=${perPage}&feature=1`,
      }).then((response) => {
        console.log(response.data.data.data, "in the carousel side featured ");
        setlatestVideo(response.data.data.data);
      });
    } catch (err) {
      console.log(err, "in the carousel side");
    }
  }, []);
  // ------------------FOR---CHILD-------------------
  useEffect(() => {
    try {
      axios({
        method: "get",
        url:
          url1 +
          `getchilduserdata?page=${currentPage}&limit=${perPage}&child=1`,
      }).then((response) => {
        console.log(response.data.data.data, "in the carousel side child ");
        setChildVideo(response.data.data.data);
      });
    } catch (err) {
      console.log(err, "in the carousel side");
    }
  }, []);

  const responsiveSettings = [
    {
      breakpoint: 992, // Tablets and smaller
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 576, // Mobile devices
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ];

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    prevArrow: <></>,
    nextArrow: <></>,
    responsive: responsiveSettings,
  };
  const [visiable, setvisiable] = useState(false);
  const test = () => {
    setvisiable(true);
    console.log(visiable, "vv");
  };
  const [show, setShow] = useState(false);

  const HandleClick = () => {
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
  };

  return (
    <>
      <div className="mapclass">
        {/* <div className="caption_wrapper">
          <h6>{props.content.Title}</h6>
        </div> */}

        <ToastContainer
          autoClose={5000}
          position="top-right"
          className="toastAlert"
          pauseOnHover={false}
        />

        {/* <div>
          <Sidebar />
        </div> */}

        <div className="mapContent" id="map">
          <div>
            <Sidebar
              handleOpenPopup={handleOpenPopup}
              handleCoordinatesUpdate={handleCoordinatesUpdate}
            />
            {openPopupId === 1 && (
              <UploadIntroPopup
                handleClosePopup={handleClosePopup}
                popupId={1}
              />
            )}
          </div>
          <MapContainer
            // center={[userWantToGo.lati, userWantToGo.long]}
            // zoom={zoom}
            scrollWheelZoom={true}
            minZoom={minZoomLevel}
            animate={true}
            markersData={markersData}
            ref={mapReff}
            center={[defaultMapView.lati, defaultMapView.long]}
            zoom={defaultMapView.zoom}
          >
            {/* ------------------Carouselpart-------------- */}
            {!show && (
              <div className="btn-glow center">
                <div className="video-carousal-before">
                  <div className="carousal-inner">
                    {latestVideo != undefined &&
                      latestVideo.slice(0, 1).map((post, index) => {
                        if (post.videoFile) {
                          return (
                            <div className="carousel-item active" key={index}>
                              <iframe
                                // width="360"
                                // height="215"
                                style={{ width: "100%", height: "100%" }}
                                src={post.videoFile}
                                frameBorder="0"
                                allowFullScreen
                                // allow="autoplay"
                              ></iframe>
                            </div>
                          );
                        }
                      })}
                  </div>

                  <span onClick={HandleClick}>
                    <ArrowCircleRightIcon />
                  </span>
                </div>
              </div>
            )}
            {show && (
              <>
                {/* <span className="testing" onClick={handleClose}>
               <i class="fa-solid fa-xmark closeIcon"></i>
               </span> */}
                <div className="carousels test">
                  <span className="testing" onClick={handleClose}>
                    <i class="fa-solid fa-xmark closeIcon"></i>
                  </span>

                  <div className="tab-content">
                    <div className=" asd tab-pane active " id="featured">
                      <div
                        id="carouselExampleAutoplaying"
                        class="carousel slide"
                        data-bs-ride="carousel"
                      >
                        <div class="carousel-inner">
                          {latestVideo != undefined &&
                            latestVideo.slice(0, 3).map((post, index) => {
                              if (post.videoFile) {
                                return (
                                  <div
                                    className="carousel-item active"
                                    key={index}
                                  >
                                    <iframe
                                      // width="360"
                                      // height="215"
                                      style={{ width: "100%", height: "100%" }}
                                      src={post.videoFile}
                                      frameBorder="0"
                                      allowFullScreen
                                      // allow="autoplay"
                                    ></iframe>
                                  </div>
                                );
                              }
                            })}
                        </div>

                        <button
                          class="carousel-control-prev "
                          type="button"
                          data-bs-target="#carouselExampleAutoplaying"
                          data-bs-slide="prev"
                        >
                          <span className="carousel-btn">
                            <span
                              class="carousel-control-prev-icon"
                              aria-hidden="true"
                            ></span>
                          </span>
                          {/* <span class="visually-hidden  Arrows">Previous</span> */}
                        </button>
                        <button
                          class="carousel-control-next "
                          type="button"
                          data-bs-target="#carouselExampleAutoplaying"
                          data-bs-slide="next"
                        >
                          <span
                            class="carousel-control-next-icon"
                            aria-hidden="true"
                          ></span>
                          {/* <span class="visually-hidden nextArrow">Next</span> */}
                        </button>
                      </div>
                    </div>

                    {/* ---------------Child--------------- */}

                    <div className=" asd tab-pane  " id="child">
                      <div
                        id="carouselExampleAutoplaying"
                        class="carousel slide"
                        data-bs-ride="carousel"
                      >
                        <div class="carousel-inner">
                          {childVideo != undefined &&
                            childVideo.slice(0, 3).map((post, index) => {
                              if (post.videoFile) {
                                return (
                                  <div
                                    className="carousel-item active"
                                    key={index}
                                  >
                                    <iframe
                                      style={{ width: "100%", height: "100%" }}
                                      src={post.videoFile}
                                      frameBorder="0"
                                      allowFullScreen
                                      // allow="autoplay"
                                    ></iframe>
                                  </div>
                                );
                              }
                            })}
                        </div>

                        <button
                          class="carousel-control-prev"
                          type="button"
                          data-bs-target="#carouselExampleAutoplaying"
                          data-bs-slide="prev"
                        >
                          <span
                            class="carousel-control-prev-icon"
                            aria-hidden="true"
                          ></span>
                          <span class="visually-hidden">Previous</span>
                        </button>
                        <button
                          class="carousel-control-next"
                          type="button"
                          data-bs-target="#carouselExampleAutoplaying"
                          data-bs-slide="next"
                        >
                          <span
                            class="carousel-control-next-icon"
                            aria-hidden="true"
                          ></span>
                          <span class="visually-hidden">Next</span>
                        </button>
                      </div>
                    </div>
                  </div>
                  <ul className="nav nav-pills C_Fvideos">
                    <li className="active  videos">
                      <a
                        href="#featured"
                        data-toggle="tab"
                        className="subnav-list CF "
                      >
                        Featured
                      </a>
                    </li>
                    {/* <li className="videos">
                      <a
                        href="#child"
                        data-toggle="tab"
                        className="subnav-list  CF"
                      >
                        Child
                      </a>
                    </li> */}
                  </ul>
                </div>
              </>
            )}
            {/* --------------Carousel-----End------------ */}

            <MapEvents />

            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              // noWrap={true}
              {...tileLayerOptions}
            />

            <LeafletgeoSearch />
            {markersData
              .filter(
                (marker) =>
                  !markersFeatureData.some(
                    (featuredMarker) => featuredMarker.id === marker.id
                  )
              )
              .filter(
                (marker) =>
                  !childMarkersFeatureData.some(
                    (featuredMarker) => featuredMarker.id === marker.id
                  )
              )
              .map((marker) => (
                <Marker
                  key={marker.id}
                  position={[marker.latitude, marker.longitude]}
                  icon={CustomMarker2}
                  ref={markerRef}
                >
                  <Popup>
                    <div className="video-popup-wrapper">
                      <div className="video-container">
                        <video
                          poster={marker.imageFile}
                          src={marker.videoFile}
                          controls
                        ></video>
                      </div>
                      <div className="description-container">
                        <h6 className="popup-content">
                          <span className="popup-title">Church Name:</span>{" "}
                          <span className="popup-details">
                            {marker.churchName}
                          </span>
                        </h6>
                        <h6 className="popup-content">
                          <span className="popup-title">Leader Name:</span>{" "}
                          <span className="popup-details">
                            {marker.leaderName}
                          </span>
                        </h6>
                        <h6 className="popup-content">
                          <span className="popup-title">Language:</span>{" "}
                          <span className="popup-details">
                            {marker.location}
                          </span>
                        </h6>
                        <h6 className="popup-content">
                          <span className="popup-title">Location:</span>{" "}
                          <span className="popup-details">
                            {marker.country}
                          </span>
                        </h6>
                      </div>
                    </div>
                  </Popup>
                </Marker>
              ))}
            {/* Marker for featured and child. */}
            {markersData
              .filter((marker) =>
                markersFeatureData.some(
                  (featuredMarker) => featuredMarker.id === marker.id
                )
              )
              .filter((marker) =>
                childMarkersFeatureData.some(
                  (featuredMarker) => featuredMarker.id === marker.id
                )
              )
              .map((marker) => (
                <Marker
                  key={marker.id}
                  position={[marker.latitude, marker.longitude]}
                  icon={CustomMarker4}
                  ref={markerRef}
                >
                  <Popup>
                    <div className="video-popup-wrapper">
                      <div className="video-container">
                        <video
                          poster={marker.imageFile}
                          src={marker.videoFile}
                          controls
                        ></video>
                      </div>
                      <div className="description-container">
                        <div className="featured-badge">
                          <Tooltip title="Featured Video">
                            <StarsIcon />
                          </Tooltip>
                          <img
                            src={child_batch}
                            alt=""
                            style={{ width: "30px", height: "30px" }}
                          />
                        </div>

                        <h6 className="popup-content">
                          <span className="popup-title">Church Name:</span>{" "}
                          <span className="popup-details">
                            {marker.churchName}
                          </span>
                        </h6>
                        <h6 className="popup-content">
                          <span className="popup-title">Language:</span>{" "}
                          <span className="popup-details">
                            {marker.location}
                          </span>
                        </h6>
                        <h6 className="popup-content">
                          <span className="popup-title">Location:</span>{" "}
                          <span className="popup-details">
                            {marker.country}
                          </span>
                        </h6>
                      </div>
                    </div>
                  </Popup>
                </Marker>
              ))}

            {/* Marker for Feature data. */}
            {markersFeatureData
              .filter(
                (marker) =>
                  !childMarkersFeatureData.some(
                    (featuredMarker) => featuredMarker.id === marker.id
                  )
              )
              .map((marker) => (
                <Marker
                  key={marker.id}
                  position={[marker.latitude, marker.longitude]}
                  icon={CustomMarker}
                  ref={markerRef}
                >
                  <Popup>
                    <div className="video-popup-wrapper">
                      <div className="video-container">
                        <video
                          poster={marker.imageFile}
                          src={marker.videoFile}
                          controls
                        ></video>
                      </div>
                      <div className="description-container">
                        <div className="featured-badge">
                          <Tooltip title="Featured Video">
                            <StarsIcon />
                            {/* <img src={child_batch} alt="" style={{ width: "30px", height: "30px" }} /> */}
                          </Tooltip>
                        </div>

                        <h6 className="popup-content">
                          <span className="popup-title">Church Name:</span>{" "}
                          <span className="popup-details">
                            {marker.churchName}
                          </span>
                        </h6>
                        <h6 className="popup-content">
                          <span className="popup-title">Leader Name:</span>{" "}
                          <span className="popup-details">
                            {marker.leaderName}
                          </span>
                        </h6>
                        <h6 className="popup-content">
                          <span className="popup-title">Language:</span>{" "}
                          <span className="popup-details">
                            {marker.location}
                          </span>
                        </h6>
                        <h6 className="popup-content">
                          <span className="popup-title">Location:</span>{" "}
                          <span className="popup-details">
                            {marker.country}
                          </span>
                        </h6>
                      </div>
                    </div>
                  </Popup>
                </Marker>
              ))}

            {/* SMH CHILDREN MARKER */}
            {childMarkersFeatureData
              .filter(
                (marker) =>
                  !markersFeatureData.some(
                    (featuredMarker) => featuredMarker.id === marker.id
                  )
              )

              // .filter()
              .map((marker) => (
                <Marker
                  key={marker.id}
                  position={[marker.latitude, marker.longitude]}
                  icon={CustomMarker3}
                  ref={markerRef}
                >
                  <Popup>
                    <div className="video-popup-wrapper">
                      <div className="video-container">
                        <video
                          poster={marker.imageFile}
                          src={marker.videoFile}
                          controls
                        ></video>
                      </div>
                      <div className="description-container">
                        <div className="featured-badge">
                          <Tooltip title="Featured Video">
                            {/* <StarsIcon /> */}
                            <img
                              src={child_batch}
                              alt=""
                              style={{ width: "30px", height: "30px" }}
                            />
                          </Tooltip>
                        </div>

                        <h6 className="popup-content">
                          <span className="popup-title">Church Name:</span>{" "}
                          <span className="popup-details">
                            {marker.churchName}
                          </span>
                        </h6>
                        <h6 className="popup-content">
                          <span className="popup-title">Leader Name:</span>{" "}
                          <span className="popup-details">
                            {marker.leaderName}
                          </span>
                        </h6>
                        <h6 className="popup-content">
                          <span className="popup-title">Language:</span>{" "}
                          <span className="popup-details">
                            {marker.location}
                          </span>
                        </h6>
                        <h6 className="popup-content">
                          <span className="popup-title">Location:</span>{" "}
                          <span className="popup-details">
                            {marker.country}
                          </span>
                        </h6>
                      </div>
                    </div>
                  </Popup>
                </Marker>
              ))}

            {coordinates && (
              <Marker
                // key={marker.id}
                position={[coordinates.lat, coordinates.lng]}
              >
                <div id="leaflet-zoom-animated">
                  <Popup
                    onClose={handlePopupClose}
                    closeOnClick={true}
                    autoClose={true}
                    maxWidth="100"
                    maxHeight="auto"
                    className="custom-leaflet-popup"
                  >
                    <div className="card col-md-6 userdata-uploadform">
                      <div className="container">
                        <form id="clearform" onSubmit={handleSubmitForm}>
                          <TextField
                            type="text"
                            name="churchName"
                            label="Name of Church/Ministry*"
                            variant="outlined"
                            className="form-control"
                            id="inputdata"
                            value={formData1.churchName}
                            onChange={(e) => {
                              setChurchName(e.target.value);
                              handleFileChange(e);
                            }}
                          />
                          {errors.churchName && (
                            <span className="uploadform-errormsg">
                              {errors.churchName}
                            </span>
                          )}

                          <TextField
                            type="text"
                            name="leaderName"
                            label="Name of Leader*"
                            variant="outlined"
                            className="form-control mt-2"
                            value={formData1.leaderName}
                            onChange={(e) => {
                              setLeaderName(e.target.value);
                              handleFileChange(e);
                            }}
                          />
                          {errors.leaderName && (
                            <span className="uploadform-errormsg">
                              {errors.leaderName}
                            </span>
                          )}

                          <div>
                            <TextField
                              type="email"
                              name="email"
                              label="Email id*"
                              variant="outlined"
                              className="form-control mt-2"
                              value={formData1.email}
                              // onChange={handleFileChange}
                              autoComplete="0"
                              onChange={(e) => {
                                setEmail(e.target.value);
                                handleFileChange(e);
                                if (email.length < 5) {
                                  setMailDisable(1);
                                }
                                if (email.length > 4) {
                                  setMailDisable(0);
                                }
                              }}
                            />

                            <button
                              class="btn btn-primary btn-block mt-2 mb-2 "
                              onClick={handleRequestOtp}
                              disabled={mailDisable}
                            >
                              Request OTP
                            </button>
                          </div>
                          {errors.email && (
                            <span className="uploadform-errormsg">
                              {errors.email}
                            </span>
                          )}
                          {mailSentFail ? (
                            <span className="uploadform-errormsg">
                              Please enter a valid mail.
                            </span>
                          ) : (
                            ""
                          )}
                          {mailSentSuccess ? (
                            <span className="uploadform-successmsg">
                              Otp sent successfully. Please check your
                              email/spam.
                            </span>
                          ) : (
                            ""
                          )}

                          {/* OTP VERIFY */}
                          <div className="opt-wrapper">
                            <div class="col-sm-12 bgWhite">
                              <div class="title">Verify OTP: </div>
                              <form action="" class="mt-2 otp-form">
                                <input
                                  id="one"
                                  // class="otp testOTP"
                                  autoComplete="0"
                                  type="text"
                                  // oninput="digitValidate(this)"
                                  // onkeyup="tabChange(1)"
                                  maxlength="4"
                                  className="otp-one"
                                  value={otpValue.one}
                                  onChange={handleOtpValue}
                                />
                                {/* <input
                                  id="two"
                                  class="otp"
                                  type="text"
                                  oninput="digitValidate(this)"
                                  onkeyup="tabChange(2)"
                                  maxlength="1"
                                  className="otp-two"
                                  value={otpValue.two}
                                  onChange={handleOtpValue}
                                  onKeyDown={(event) =>
                                    handleBackspace(event, "two")
                                  }
                                />
                                <input
                                  id="three"
                                  class="otp"
                                  type="text"
                                  oninput="digitValidate(this)"
                                  onkeyup="tabChange(3)"
                                  maxlength="1"
                                  className="otp-three"
                                  value={otpValue.three}
                                  onChange={handleOtpValue}
                                  onKeyDown={(event) =>
                                    handleBackspace(event, "three")
                                  }
                                />
                                <input
                                  id="four"
                                  class="otp"
                                  type="text"
                                  oninput="digitValidate(this)"
                                  onkeyup="tabChange(4)"
                                  maxlength="1"
                                  className="otp-four"
                                  value={otpValue.four}
                                  onChange={handleOtpValue}
                                  onKeyDown={(event) =>
                                    handleBackspace(event, "four")
                                  }
                                /> */}
                              </form>
                              <br />
                              <button
                                class="btn btn-primary btn-block mt-2 mb-2 customBtn"
                                onClick={handleVerifyOtp}
                                disabled={enableVerify}
                                style={{
                                  borderRadius: "5px",
                                  width: "100px",
                                  letterSpacing: "2px",
                                }}
                              >
                                Verify
                              </button>
                            </div>
                          </div>
                          {otpVerifyFail ? (
                            <span className="uploadform-errormsg">
                              Please double-check the OTP you provided.
                            </span>
                          ) : (
                            ""
                          )}
                          {otpVerifySuccess ? (
                            <span className="uploadform-successmsg">
                              OTP verification successful.
                            </span>
                          ) : (
                            ""
                          )}

                          <div class="input-group custom-file-button ">
                            <label
                              class="input-group-text"
                              htmlFor="inputVideoFile"
                            >
                              Upload Video*
                            </label>
                            <input
                              // hidden
                              type="file"
                              name="videoFile"
                              accept="video/*"
                              class="form-control"
                              id="inputVideoFile"
                              onChange={(e) => {
                                setVideoFile(e.target.value);
                                handleFileChange(e);
                              }}
                              disabled={hideFormElements}
                            />
                          </div>
                          {errors.videoFile && (
                            <span className="uploadform-errormsg">
                              {errors.videoFile}
                            </span>
                          )}
                          <span id="fileSizeMessage"></span>

                          <div class="input-group custom-file-button">
                            <label
                              class="input-group-text"
                              htmlFor="inputImageFile"
                            >
                              Upload Image*
                            </label>
                            <input
                              // hidden
                              type="file"
                              name="imageFile"
                              accept="image/*"
                              class="form-control"
                              id="inputImageFile"
                              onChange={(e) => {
                                setImageFile(e.target.value);
                                handleFileChange(e);
                              }}
                              disabled={hideFormElements}
                            />
                          </div>
                          {errors.imageFile && (
                            <span className="uploadform-errormsg">
                              {errors.imageFile}
                            </span>
                          )}

                          <TextField
                            type="text"
                            name="location"
                            // label="Location*"
                            label="Language*"
                            variant="outlined"
                            className="form-control mt-2"
                            value={formData1.location}
                            onChange={(e) => {
                              setLocation(e.target.value);
                              handleFileChange(e);
                            }}
                            disabled={hideFormElements}
                          />

                          {errors.location && (
                            <span className="uploadform-errormsg">
                              {/* {errors.location} */}
                              Language is required
                            </span>
                          )}
                          {/* <TextField
                            type="text"
                            name="country"
                            label="Language"
                            variant="outlined"
                            className="form-control mt-2"
                            value={formData1.country}
                            // onChange={handleFileChange}
                            onChange={(e) => {
                              setLanguage(e.target.value);
                              handleFileChange(e);
                            }}
                            disabled={hideFormElements}
                          />
                          {errors.language && (
                            <span className="uploadform-errormsg">
                              {errors.language}
                            </span>
                          )} */}

                          <TextField
                            type="text"
                            name="country"
                            label="Country*"
                            variant="outlined"
                            className="form-control mt-2"
                            value={formData1.country}
                            // onChange={handleFileChange}
                            onChange={(e) => {
                              setLanguage(e.target.value);
                              handleFileChange(e);
                            }}
                            disabled={hideFormElements}
                          />
                          {errors.language && (
                            <span className="uploadform-errormsg">
                              {errors.language}
                            </span>
                          )}

                          <TextField
                            type="number"
                            name="mobile"
                            label="Mobile No"
                            variant="outlined"
                            className="form-control mt-2"
                            value={formData1.mobile}
                            // onChange={handleFileChange}
                            onChange={(e) => {
                              setMobile(e.target.value);
                              handleFileChange(e);
                            }}
                            disabled={hideFormElements}
                            onInput={(e) => {
                              e.target.value = Math.max(
                                0,
                                parseInt(e.target.value)
                              )
                                .toString()
                                .slice(0, 10);
                            }}
                          />
                          {errors.mobile && (
                            <span className="uploadform-errormsg">
                              {errors.mobile}
                            </span>
                          )}

                          <TextField
                            type="text"
                            name="suggestions"
                            label="Additional details or recommendationss"
                            variant="outlined"
                            className="form-control mt-2"
                            value={formData1.suggestions}
                            onChange={handleFileChange}
                            disabled={hideFormElements}
                          />
                          <TextField
                            type="text"
                            name="latitude"
                            label="Latitude:"
                            variant="outlined"
                            className="form-control mt-2"
                            id="coordinatesInput"
                            disabled
                            value={coordinates ? coordinates.lat : ""}
                            onChange={handleFileChange}
                          />
                          <TextField
                            type="text"
                            name="longitude"
                            label="Longitude: "
                            variant="outlined"
                            className="form-control mt-2"
                            disabled
                            id="coordinatesInput"
                            value={coordinates ? coordinates.lng : ""}
                            onChange={handleFileChange}
                          />
                        </form>
                        <div className="card-footer row formUserFoot">
                          <div className="btnCol col-12 upload-submit-btn">
                            <button
                              // type="submit"
                              className="btn"
                              // size="large"
                              // variant="contained"
                              onClick={confirmpopup}
                            >
                              {" "}
                              SUBMIT{" "}
                            </button>

                            <Modal show={showModal} onHide={handleCloseModal}>
                              <Modal.Header closeButton>
                                <Modal.Title>
                                  <b
                                    className="confirm-head"
                                    style={{ color: "#002daa" }}
                                  >
                                    Confirmation
                                  </b>
                                </Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                <p>Are you sure you want to submit the form?</p>
                              </Modal.Body>
                              <Modal.Footer>
                                <Button
                                  variant="secondary"
                                  onClick={handleCloseModal}
                                >
                                  Cancel
                                </Button>
                                <Button
                                  variant="primary"
                                  onClick={handleSubmitForm}
                                >
                                  Confirm
                                </Button>
                              </Modal.Footer>
                            </Modal>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Popup>
                </div>
              </Marker>
            )}
          </MapContainer>
        </div>

        {/* <div className="playerClass">
          <button
            className="btn btn-primary"
            id="playAudioBtn"
            onClick={playAudio}
          >
            Play
          </button>
          <div className="playercontainer">
            <AudioPlayer
              preload="none"
              autoPlay={false}
              src={song}
              volume={0.5}
              loop={true}
              layout="horizontal-reverse"
              footer="Playing : OH Lord my God on PAN FLUTE - Nick Tacu"
              ref={player}
            />
          </div>
        </div> */}

        {/* <div className="playPauseBtn">
          <img
            id="playIconBtn"
            onClick={playAudio}
            src={playIcon}
            alt="playIcon"
          />
          <img
            id="pauseIconBtn"
            onClick={pauseAudio}
            src={pauseIcon}
            alt="pauseIcon"
          />
        </div> */}

        <div className="row container-fluid videoContent">
          <div className="col-3 videoCol hideVid">
            {/* <video controls ref={videoPlayer1}>
                        <source src={video1} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video> */}
          </div>
          <div className="col-3 videoCol hideVidMain">
            <video controls ref={videoPlayer2}>
              <source src={video2} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
          <div className="col-3 videoCol hideVidMain">
            <video controls ref={videoPlayer3}>
              <source src={video3} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>

          <div class="container col-3 videoCol hideVidMain">
            {/* <div className="videowrapper">
            <iframe
              // width="560"
              // height="315"
              class="responsive-iframe"
              src="https://www.youtube.com/embed/krR6yLyAYog?rel=0"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </div> */}
          </div>
        </div>

        {/* <div id="userForm" className="userForm">
          <div className="middle">
            <div className="formContent">
              <div className="formContent1">
                <span>A WEBSITE to HOST (THEN SINGS MY SOUL)</span>
                <span>THE CLASSIC - OH LORD MY GOD</span>
                <span>IN ALL LANGUAGES of the WORLD</span>
              </div>
              <div className="formContent2">
                <span>
                  A WEBSITE TO HOST THE CLASSIC - OH LORD MY GOD (THEN SINGS MY
                  SOUL) IN ALL LANGUAGES OF THE WORLD
                </span>
              </div>
              <div className="formContent3">
                <span className="">
                  Send us your <b>Email</b> for updates and launch details
                </span>
              </div>
            </div>
            <div className="formUser">
              <TextField
                type="text"
                name="firstName"
                label="First Name"
                variant="outlined"
                className="form-control"
                onChange={handleInputChange}
              />
              <TextField
                type="text"
                name="lastName"
                label="Last Name"
                variant="outlined"
                className="form-control mt-2"
                onChange={handleInputChange}
              />
              <TextField
                type="email"
                error={error}
                helperText={error && "Email required"}
                name="email"
                label="Email"
                variant="outlined"
                className="form-control mt-2"
                placeholder="Example@gmail.com"
                autoComplete="off"
                onChange={handleInputChange}
                required
              />
              <TextField
                type="text"
                name="location"
                label="Location"
                variant="outlined"
                className="form-control mt-2"
                onChange={handleInputChange}
              />

              <div className="btnCol">
                <Button
                  className="cancelBtn"
                  size="large"
                  variant="contained"
                  color="error"
                  onClick={handleCancel}
                >
                  Close
                </Button>
                <Button
                  type="submit"
                  className="btn btn-primary submitLeafMain "
                  size="large"
                  variant="contained"
                  onClick={handleSubmit}
                  disabled={btn}
                >
                  SUBMIT
                </Button>
              </div>
              <span className="">
                <i class="bi bi-lock-fill"></i>100% Privacy Guaranteed
              </span> */}

        {/* </div>
          </div> */}
        {/* </div> */}
      </div>
    </>
  );
};

export default LeafMap;
