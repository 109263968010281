import React, { useEffect, useRef, useState } from "react";
import L from "leaflet";
import { renderToString } from "react-dom/server";
import { Icon } from "@material-ui/core";
import PersonPinCircleIcon from "@mui/icons-material/PersonPinCircle";
import "../../styles/CustomMarker.scss";

const customIconClass2 = "leaflet-div-icon2";

const CustomMarker2 = new L.Icon({
  iconUrl: require("../../assets/images/nn-r.png"),
  iconRetinaUrl: require("../../assets/images/nn-r.png"),
  iconAnchor: [0, 0],
  popupAnchor: [0, -20],
  //   shadowUrl: 'path/to/shadow-image.png',
  shadowSize: [20, 20],
  shadowAnchor: [10, 10],
  iconSize: new L.Point(40, 40),
  className: customIconClass2,
});

export default CustomMarker2;
